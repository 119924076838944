
import React from 'react';
//import bg from '../assets/images/pexels-ksenia-chernaya-3965543HD.jpg';
import sig from '../assets/images/sig.png';
import docenciaImg from '../assets/images/docencia.jpg';

import Article from '../components/Article';
import SubPageStyles from '../styles/SubPageStyles';



export default function ConsultingPage() {
  return (
    <SubPageStyles >
        <Article title="Consultoría">
            <h3>¿Ha identificado su organización cómo responder en forma práctica y segura a los tres clientes de su organización y a las partes interesadas?</h3>
        
        <p>Acompañamos a las organizaciones en la gestión del negocio a través del desarrollo de una metodología práctica y funcional para impulsar los sistemas de gestión, que vienen a convertirse en la herramienta clave para el logro de la estrategia en la organización. Con el compromiso y acompañamiento de la alta dirección se la sensibiliza en el papel importante que juegan los sistemas de gestión en sus diferentes responsabilidades y autoridades, y se lleva a cabo el despliegue de las acciones claves para que en todos los nivele de la organización se adopten los direccionamientos estratégicos.</p>
        <p>Esta misma filosofía es aplicada en los diferentes temas que la organización quiera involucrar en la consultoría. No sobra decir que el enfoque se hace bajo los nuevos lineamientos de “estructura de alto nivel” a los que se han llevado los diferentes sistemas de gestión. Para algunas personas suena como nuevas exigencias, pero, en realidad, son metodologías en el mundo empresarial que se vienen manejando desde hace más de treinta años y podemos llevarlas a sus organizaciones por la experiencia adquirida en grandes empresas donde hemos laborado a lo largo de nuestra trayectoria profesional.</p>

        
        <img src={sig} />
        <h3>Sistema de gestión integrada</h3>
        <br />
        <h4>TIENES LA GRAN OPORTUNIDAD DE IMPLEMENTAR UN SISTEMA DE GESTIÓN INTEGRADA</h4>
            
            <p>
            El mundo empresarial cada vez se torna más agresivo e inclemente dada la competencia que se afronta tanto a nivel nacional como a nivel internacional en los diferentes sectores. Esta es una situación que afecta en diferentes grados a las organizaciones: dependiendo de su tamaño, su capital y su solidez en el mercado. Hay varias razones por las cuales, muchas organizaciones deciden implementar sistemas de gestión, principalmente: <b>calidad, ambiente, seguridad, salud e inocuidad.</b> Este último muy en boga actualmente debido a las exigencias regulatorias legales, y técnicas que se han ido volviendo más y más exigentes en los países.
            </p>
            <p>Ahora, las razones aducidas pueden dividirse entre las que se dan por convicción y las que se dan por obligación. En el primer grupo está la decisión de tener una organización más homogénea y disciplinada para lograr los propósitos establecidos y así alcanzar un “PyG” sano al final de la función (cada mes o anualmente). En el segundo grupo, están las decisiones que se toman de tener un sistema para poder licitar y participar en oportunidades que brinda el mercado, pero no hay una fundamentación objetiva sobre la importancia del sistema y este solo es trabajado bajo el concepto de documentación, sin entender los otros componentes. La documentación es importante pero no determinante en el éxito de un sistema de gestión.</p>

            <p>Entonces, aparece en este punto, la importancia de tener un sistema de gestión integrada expresión que nos indica que no son los sistemas los que se integran, sino la gestión que se viene haciendo por separado en cada uno de ellos. Así como el mundo empresarial va con una dinámica cambiante en períodos muy cortos, también la gestión de la organización debe ir en esa misma tónica para adaptarse a los cambios.</p>
           
           <p>Desde hace unos cuantos años el concepto de la implementación de un sistema de gestión integrada ha dado un giro, en relación, a como se acostumbraba a implementarlos anteriormente. Las organizaciones iniciaban con calidad, luego seguían con ambiental por tener una estructura mas o menos similar y, por último, dejaban seguridad y salud por la complejidad en el cumplimiento legal y en el manejo de requisitos de norma mas exigentes. Ahora, desde hace unos diez años, las organizaciones más avanzadas en el mundo han entendido que lo más importante es tener primero a sus colaboradores seguros; luego sanos, física y mentalmente; y, por último, amigables con el ambiente. Aquí ya podemos hablar de tener calidad integral. Mientras que una organización no puede jactarse de tener calidad, cuando su recurso más preciado, sus trabajadores, no están siendo considerados dentro de las prioridades (mantenerlo seguro y sano).

Se suman otras características positivas al integrar el sistema de gestión en estos tres campos y en el de inocuidad, destacándose, entre ellas: la optimización de recursos, al tener decisiones unificadas que den respuesta a todos los sistemas al mismo tiempo; una sola revisión por la dirección; y asignar recursos genéricos que sirvan para llevar a cabo proyectos o actividades en conjunto, sin dejar a un lado la formación de los colaboradores en los tres temas. De este modo se vuelve a los trabajadores, colaboradores integrales y estables.</p>

<br />
            <h3>Docencia Empresarial</h3>
            <br />
            <p>
            La formación de <b>líderes</b> ha comprobado ser uno de los nortes en cada país del mundo en el que he tenido la oportunidad de constatarlo. Esta consiste en educar a las generaciones en crecimiento para que al llegarles la oportunidad en cualquier ámbito, suplan con solvencia aquellos espacios dejados por las generaciones anteriores; cuyo esfuerzo les permitió ubicarse en sitios preferenciales. La <b>educación empresarial</b> es entonces, una de las herramientas que utilizan las corporaciones para fomentar la capacidad de liderazgo, mantener un personal altamente calificado e incrementar la producción a niveles de excelencia.
            </p>

            <img src={docenciaImg}/>



        </Article>
    </SubPageStyles>
  );
}
